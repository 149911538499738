import * as React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'

import 'modern-normalize'
import '../styles/normalize'

import LayoutRoot from '../components/LayoutRoot'
import Img, { FixedObject, FluidObject } from 'gatsby-image'
import styles from '../styles/home.module.css'
import classnames from 'classnames'

/**
 * Wrap the components
 */
export interface FoodRecipeInterface {
  node: {
    id: number
    strapiId: string
    slug: string
    name: string
    created_at: Date
    description: string
    ingredients: string
    instructions: string
    images: {
      childImageSharp: {
        fixed: FixedObject
        fluid: FluidObject
      }
    }
  }
}

interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string
      description: string
      keywords: string
    }
  }
  allStrapiRecipe: {
    edges: FoodRecipeInterface[] // definition for the data model
  }
}

interface Props {
  readonly title?: string
  readonly children: React.ReactNode
}

/**
 * IndexPage is using IndexLayout
 */

const IndexLayout: React.FC<Props> = ({ children }) => (
  <StaticQuery
    query={graphql`
      query IndexLayoutQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
        allStrapiRecipe(limit: 20, sort: {fields: created_at, order: DESC}) {
          edges {
            node {
              strapiId
              slug
              name
              description
              ingredients
              instructions
              images {
                childImageSharp {
                  fluid(maxWidth: 1000, quality: 100) {
                    ...GatsbyImageSharpFluid
                    ...GatsbyImageSharpFluidLimitPresentationSize
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data: StaticQueryProps) => {
      var stuff: any[] = []
      data.allStrapiRecipe.edges.forEach((item, index) => {
        const url = `/recipes/${item.node.slug}`
        const image = <Img className={classnames(styles.image)} fluid={item.node.images.childImageSharp.fluid} />
        stuff.push(
            <a key={index} className={classnames(styles.recipeBlock)} href={url}>
                {image}
              
              <a className={classnames(styles.recipeName)} href={url}>{item.node.name}</a>  
            </a>
        )
      })
      return <LayoutRoot>
        <div className={classnames(styles.main)}>
            <h1 className={classnames(styles.h1)}>Popular Food Recipes</h1>
            <div className={classnames(styles.recipeGrid)}>
              {stuff}
            </div>
          
          </div>
      </LayoutRoot>
    }}
  />
)

export default IndexLayout